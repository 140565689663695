<template>
    <v-card class="beereaders-chip" rounded="lg" flat color="deep-purple lighten-5 pa-3 px-6">
        <slot></slot>
    </v-card>
</template>

<script>
export default {
    name: 'SimpleChip'
}
</script>